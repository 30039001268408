import "./styles.css"
export default function Navbar(){
    return <nav className="nav">
        <a href="/" className="site-title">Startseite</a>
        <ul>
            <li>
                <a href="/Messe">Messe</a>
            </li>
            <li>
                <a href="/Kontaktieren">Kontaktieren</a>
            </li>
        </ul>
    </nav>
}
/*<p>Willkommen auf meiner persönlichen Website! Mein Name ist Felix bzw. Messe, ich habe die meisten Texte mit ChatGTP erstellt und freue mich,
            dass Sie den Weg hierher gefunden haben.</p>
            <p>Auf dieser Website möchte ich meine Gedanken und Erfahrungen mit Ihnen teilen und eigentlich nur üben. 
            Ich liebe es, Neues zu entdecken und mich weiterzubilden, und hoffe, dass meine Beiträge interessant und nützlich für Sie sein werden.</p>
            <p>Hier finden Sie eine Auswahl meiner Lieblingsthemen, sowie auch Bilder und andere kreative Projekte, die ich in der 
                Vergangenheit gemacht habe. Ich hoffe, dass Sie hier etwas Interessantes finden und gerne weiter browsen. Falls Sie Fragen haben oder mit
                 mir in Kontakt treten möchten, finden Sie auf der Seite auch meine Kontaktdaten.</p> 

            <p>Vielen Dank, dass Sie sich die Zeit genommen haben, meine Website zu besuchen und ich hoffe, Sie werden wiederkommen.</p>
            <p>Beste Grüße</p>
            <p>Messe</p>*/