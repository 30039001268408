import Navbar from "./Navbar";
import Messe from "./pages/Messe";
import Kontaktieren from "./pages/Kontaktieren";
import Startseite from "./pages/Startseite";
import "./styles.css"


function App() {
  let seite
  switch (window.location.pathname) {
    case "/":
      seite = <Startseite />
      break;
    case "/Messe":
      seite = <Messe />
      break;
    case "/Kontaktieren":
      seite = <Kontaktieren />
      break;
    default:
      seite = <Startseite />
      break;
  }
  return (
    <>
      <Navbar />
      <div className="container">{seite}</div>
    </>
  );
}


export default App;
